import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import Contact from '../components/Contact/Contact'
import PageBanner from '../components/Common/PageBanner'
import GoogleMap from '../components/Contact/GoogleMap'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const ContactPage = () => {
    return (
        <Layout>
            <Seo title="Contact" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Contact Us" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Contact" 
            />

            <Contact />

            <GoogleMap />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default ContactPage
