import React from 'react'

const ContactSideBar = () => {
    return (
        <div className="contact-card-area pr-20">
            <div className="default-section-title">
                <h3>Have A Project Between Mind? Say Hi!</h3>
                <p>Please give us a call, drop us an email, or fill out the contact form and we'll get back to you.</p>
            </div>
            <div className="contact-card">
                <h4><i className="fas fa-map-marker-alt"></i> Address: </h4>
                <p><a href="https://goo.gl/maps/igV8NqPfViMJx2x69" rel="noreferrer">32 Street Name, New York, USA</a></p>
            </div>
            <div className="contact-card">
                <h4><i className="fas fa-envelope"></i> Email: </h4>
                <p><a href="mailto:hello@glit.com">hello@glit.com</a></p>
            </div>
            <div className="contact-card">
                <h4><i className="fas fa-phone"></i> Call: </h4>
                <p><a href="tel:+029222110">+(029-22-2110)</a></p>
            </div>
            <div className="contact-card">
                <h4>Follow Us:</h4>
                <ul>
                    <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram" ></i></a></li>
                </ul>
            </div>
        </div> 
    )
}

export default ContactSideBar
