import React from 'react'
import ContactSideBar from './ContactSideBar'


const Contact = () => {
    return (
        <section className="contact ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <ContactSideBar />
                    </div>

                    <div className="col-lg-8">
                        <div className="contact-page-form-area pt-30">
                            <form id="contactForm">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Name*" 
                                                id="name" 
                                                required data-error="Please enter your name"
                                            /> 
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <input 
                                                type="email" 
                                                name="email" 
                                                className="form-control" 
                                                placeholder="Email*" 
                                                id="email" 
                                                required data-error="Please enter your Email"
                                            /> 
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="phone_number" 
                                                className="form-control" 
                                                placeholder="Phone Number*" 
                                                id="phone_number" 
                                                required data-error="Please enter your phone number"
                                            />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="msg_subject" 
                                                className="form-control" 
                                                placeholder="Subject" 
                                                id="msg_subject" 
                                                required data-error="Please enter your subject"
                                            />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="company_name" 
                                                className="form-control" 
                                                placeholder="Company Name*" 
                                                id="company_name" 
                                                required data-error="Please enter your Company Name"
                                            />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="website" 
                                                className="form-control" 
                                                placeholder="Website Link*" 
                                                id="website" 
                                                required data-error="Please enter your Website Link"
                                            />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="form-group">
                                            <textarea 
                                                name="message" 
                                                id="message" 
                                                className="form-control" 
                                                placeholder="Your Messages.." 
                                                cols="30" rows="5" 
                                                required data-error="Please enter your message">    
                                            </textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <button className="default-button default-button-3" type="submit"><span>Send Message <i className="fas fa-arrow-right"></i></span></button>
                                        <div id="msgSubmit" className="h6 text-center hidden"></div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
