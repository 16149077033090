import { Link } from 'gatsby'
import React from 'react'

const PageBanner = ({pageTitle, homePageUrl, homePageText, activePageText}) => {
    return (
        <section className="uni-banner">
            <div className="container">
                <div className="uni-banner-text-area">
                    <h1>{pageTitle}</h1>
                    <ul>
                        <li><Link to={homePageUrl}>{homePageText}</Link></li>
                        <li>{activePageText}</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default PageBanner
